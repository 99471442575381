@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.selectQuestionMA {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
}

.selectQuestionMAContainerMA {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectQuestionMAContainerMA > h1 {
  margin-top: 1rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 20px 30vw;
  border-radius: 8px;
}

.selectQuestionMAContainerMA__top {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectQuestionMAContainerMA__top > p {
  margin: 0rem 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: black;
  width: 15vw;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 30px;
}

.selectQuestionMAContainerMA__middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selectQuestionMAContainerMA__middleSelect {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.selectQuestionMAContainerMA__middleSelect > p {
  margin-right: 1rem;
  font-family: "Inter", sans-serif;
  width: 12vw;
  color: white;
}

.selectQuestionMAContainerMA > button {
  margin-top: 1rem;
  padding: 10px 50px;
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1.1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.selectQuestionMAContainerMA > button:hover {
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
}

.questionContainerMA {
  /* padding: 1rem; */
  height: 100%;
  background-color: transparent;
  border-radius: 15px;
  /* overflow: scroll; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.questionContainerMA__question > h3 {
  text-align: center;
  background-color: #fcaa87;
  margin-right: 2rem;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 600;
  color: white;
}

.questionContainerMA__question {
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
}

.questionContainerMA__question > textarea {
  font-size: 1rem !important;
  outline-width: 0;
  border-radius: 9px;
  padding: 5px;
  width: 100%;
}

.optionsContainerMA {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.options__containerMA {
  margin: 1rem 0;
  display: flex;
}

.optionsContainerMA > .options__containerMA > input {
  height: 40px;
  margin-right: 1rem;
  outline-width: 0;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid gray;
  margin-bottom: 1rem;
}

.TFtimer__section {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TFtimer__section > label {
  font-weight: 600;
}

.TFtimer__section > input {
  outline-width: 0;
  margin-left: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}

.selectAnswerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectAnswerContainer > label {
  margin-right: 10px;
  font-weight: 600;
}

.questionContainerMA > button {
  margin-top: 1rem;
  padding: 7px 30px;
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.questionContainerMA > button:hover {
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
}

.selectQuestionMA > button {
  margin: auto;
  width: 15vw;
  margin-top: 1rem;
  padding: 7px 30px;
  background: #fcaa87;
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.selectQuestionMA > button:hover {
  background: #fcaa87;
  border-radius: 8px;
}

.selectAnswerContainer > input {
  outline-width: 0;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 5px 5px;
  border-radius: 15px;
  border: 1px solid gray;
  margin-bottom: 1rem;
}

.questionHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.questionHeading > label {
  color: white;
  margin-right: 10px;
}

.questionHeading > input {
  padding: 7px 10px;
  margin-right: 1rem;
  border-radius: 8px;
  outline-width: 0;
  width: 30vw;
  border: 1px solid gray;
}

.mca__uploadSection {
  display: flex;
  align-items: center;
}

.mca__uploadSection > div {
  display: flex;
}

.mca__uploadSection > div > p {
  margin-top: 3px;
  color: white;
}

.mca__uploadSection > div > div > button {
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.mca__uploadSection > div > div > button i {
  margin-right: 5px;
}

.delete__icon {
  color: #fff;
  margin-left: 0.5rem;
  cursor: pointer;
}

.uploadVideoSection {
  margin-bottom: 2rem;
  color: #fff;
}

.uploadVideoSection > div > button {
  margin-top: 2rem;
  margin-left: 1rem;
  padding: 10px 20px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.uploadVideoSection > div > button i {
  margin-right: 10px;
}
