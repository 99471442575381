@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.banner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.mockTest__row > .image {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
}
