@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  /* overflow-x: hidden; */
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right bottom, #61e1eb, #327bc5);
  border-radius: 25px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

.classSelection__header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  width: 80vw;
  padding: 20px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.classSelection__header > h1 {
  display: flex;
  justify-content: center;
  flex: 1 1;
  color: white;
}

.logout {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
}

.logout > h4 {
  margin-left: 10px;
}

.Modal {
  background-color: #bbb7b7;
}

.close-modal {
  position: absolute;
  padding: 6px 10px;
  font-size: 1.2rem;
  right: 1rem;
  top: 1.5rem;
  z-index: 1000;
  cursor: pointer;
}

.TFtimer__section {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TFtimer__section > label {
  font-weight: 600;
}

.TFtimer__section > input {
  outline-width: 0;
  margin-left: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}

.classMedium {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.classMedium {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classMedium > h1 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 20px 30vw;
  border-radius: 8px;
}

.selectContainer__middleSelect {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.selectContainer__middleSelect > p {
  margin-right: 1rem;
  font-family: "Inter", sans-serif;
  width: 10vw;
  color: white;
}

.classMediumContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.classMediumContainer > button {
  outline-width: 0;
  border: none;
  font-size: 1.2rem;
  color: white;
  background-color: #fcaa87;
  padding: 10px 30px;
  border-radius: 9px;
  cursor: pointer;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.title > label {
  width: 8vw;
}

.title > input {
  outline-width: 0;
  width: 40vw;
  border: none;
  margin-bottom: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
}

.mockTest__row > .image {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
}

.fileContainer {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.fileContainer {
  display: flex;
  align-items: center;
}

.fileContainer > p {
  margin-top: 3px;
  color: white;
  width: 10vw;
}

.fileContainer > div > div > button {
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.fileContainer > div > div > button i {
  margin-right: 5px;
}

.selectQuestionMA {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
}

.selectQuestionMAContainerMA {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectQuestionMAContainerMA > h1 {
  margin-top: 1rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 20px 30vw;
  border-radius: 8px;
}

.selectQuestionMAContainerMA__top {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectQuestionMAContainerMA__top > p {
  margin: 0rem 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: black;
  width: 15vw;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 30px;
}

.selectQuestionMAContainerMA__middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selectQuestionMAContainerMA__middleSelect {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.selectQuestionMAContainerMA__middleSelect > p {
  margin-right: 1rem;
  font-family: "Inter", sans-serif;
  width: 12vw;
  color: white;
}

.selectQuestionMAContainerMA > button {
  margin-top: 1rem;
  padding: 10px 50px;
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1.1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.selectQuestionMAContainerMA > button:hover {
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
}

.questionContainerMA {
  /* padding: 1rem; */
  height: 100%;
  background-color: transparent;
  border-radius: 15px;
  /* overflow: scroll; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.questionContainerMA__question > h3 {
  text-align: center;
  background-color: #fcaa87;
  margin-right: 2rem;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 600;
  color: white;
}

.questionContainerMA__question {
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
}

.questionContainerMA__question > textarea {
  font-size: 1rem !important;
  outline-width: 0;
  border-radius: 9px;
  padding: 5px;
  width: 100%;
}

.optionsContainerMA {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.options__containerMA {
  margin: 1rem 0;
  display: flex;
}

.optionsContainerMA > .options__containerMA > input {
  height: 40px;
  margin-right: 1rem;
  outline-width: 0;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid gray;
  margin-bottom: 1rem;
}

.TFtimer__section {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TFtimer__section > label {
  font-weight: 600;
}

.TFtimer__section > input {
  outline-width: 0;
  margin-left: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}

.selectAnswerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectAnswerContainer > label {
  margin-right: 10px;
  font-weight: 600;
}

.questionContainerMA > button {
  margin-top: 1rem;
  padding: 7px 30px;
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.questionContainerMA > button:hover {
  background: #fcaa87;
  box-shadow: inset 0px 4px 4px rgba(43, 155, 255, 0.25);
  border-radius: 8px;
}

.selectQuestionMA > button {
  margin: auto;
  width: 15vw;
  margin-top: 1rem;
  padding: 7px 30px;
  background: #fcaa87;
  border-radius: 8px;
  color: white;
  letter-spacing: 1px;
  font-size: 1rem;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
}

.selectQuestionMA > button:hover {
  background: #fcaa87;
  border-radius: 8px;
}

.selectAnswerContainer > input {
  outline-width: 0;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 5px 5px;
  border-radius: 15px;
  border: 1px solid gray;
  margin-bottom: 1rem;
}

.questionHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.questionHeading > label {
  color: white;
  margin-right: 10px;
}

.questionHeading > input {
  padding: 7px 10px;
  margin-right: 1rem;
  border-radius: 8px;
  outline-width: 0;
  width: 30vw;
  border: 1px solid gray;
}

.mca__uploadSection {
  display: flex;
  align-items: center;
}

.mca__uploadSection > div {
  display: flex;
}

.mca__uploadSection > div > p {
  margin-top: 3px;
  color: white;
}

.mca__uploadSection > div > div > button {
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.mca__uploadSection > div > div > button i {
  margin-right: 5px;
}

.delete__icon {
  color: #fff;
  margin-left: 0.5rem;
  cursor: pointer;
}

.uploadVideoSection {
  margin-bottom: 2rem;
  color: #fff;
}

.uploadVideoSection > div > button {
  margin-top: 2rem;
  margin-left: 1rem;
  padding: 10px 20px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.uploadVideoSection > div > button i {
  margin-right: 10px;
}

.banner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.mockTest__row > .image {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
}

.mockTestContainer {
  width: 100vw;
  min-height: 120vh;

  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mockTestContainer__top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
}

.section__fields {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}

.section__fields > p {
  color: #fff;
}

.select__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  margin-left: 2rem;
}

.text__container {
  color: #fff;
  margin-left: 2rem;
}

.text__container > input {
  padding: 8px;
  outline-width: 0;
  border: none;
  border-radius: 9px;
}

.mockTest__timer {
  color: #fff;
}

.mockTest__timer > input {
  padding: 8px;
  outline-width: 0;
  border: none;
  border-radius: 9px;
}

.section__buttons {
  display: flex;
}

.fileContainer {
  margin-bottom: 1rem;
}

* {
  font-family: "Inter", sans-serif;
}

.editMockTest {
  width: 95vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.editMockTest {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editMockTest__containerTop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.preview__image {
  width: 30px;
  height: 35px;
  border-radius: 9px;
  background: beige;
  object-fit: contain;
  cursor: pointer;
}

/* .editMockTest__container {
      display: flex;
  } */

.editMockTest__container > button {
  margin-top: 2rem;
  margin-left: 1rem;
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: #fcaa87;
  font-weight: 600;
  color: white;
  margin: 1rem auto;
}

.editMockTest__container > button:hover {
  background-color: #fcaa87;
}

.MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.edit__input {
  outline-width: 0;
  border: none;
  background-color: #f5e3dd;
  padding: 5px 10px;
  width: 80%;
  border-radius: 10px;
  margin-left: 5px;
}

.editMockTest > button {
  margin-left: 1rem;
  margin: 1rem 0;
  color: white;
  background-color: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.editMockTest > button:hover {
  background-color: #fcaa87;
}

.mockContainer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 2rem;
}

.classSelector {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  grid-gap: 1rem;
  gap: 1rem;
}

.buttonContainer > button {
  margin-left: 1rem;
  margin-top: 1rem;
  color: white;
  background-color: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.buttonContainer > button:hover {
  background-color: #fcaa87;
}

.mockTest__row {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0rem;
}

.mockTest__row > p {
  background-color: white;
  padding: 7px;
  margin-left: 1rem;
  min-width: 10vw;
  border-radius: 9px;
  text-align: center;
  cursor: pointer;
}

.mockTest__row > label {
  padding: 10px 20px;
  background-color: #fcaa87;
  border-radius: 9px;
  color: white;
}

.preview > button {
  outline-width: 0;
  background: transparent;
  border: 1px solid black;
  color: black;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
}

.addBanner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.addBannerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addBanner > button {
  margin-top: 5rem;
  margin-left: 1rem;
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: #fcaa87;
  font-weight: 600;
  color: white;
  margin: 1rem auto;
}

.addBanner > button:hover {
  background-color: #fcaa87;
}

.select {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: black;
  text-align: center;
}

.css-tj5bde-Svg {
  color: black;
}

.css-2613qy-menu {
  z-index: 999;
  min-height: 100%;
}

.image {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
}

