@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("./UploadMockTest/UploadMockTest.css");
@import url("./EditMockTest/EditMockTest.css");

.mockContainer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 2rem;
}

.classSelector {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  gap: 1rem;
}

.buttonContainer > button {
  margin-left: 1rem;
  margin-top: 1rem;
  color: white;
  background-color: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.buttonContainer > button:hover {
  background-color: #fcaa87;
}

.mockTest__row {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0rem;
}

.mockTest__row > p {
  background-color: white;
  padding: 7px;
  margin-left: 1rem;
  min-width: 10vw;
  border-radius: 9px;
  text-align: center;
  cursor: pointer;
}

.mockTest__row > label {
  padding: 10px 20px;
  background-color: #fcaa87;
  border-radius: 9px;
  color: white;
}
