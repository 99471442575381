.preview > button {
  outline-width: 0;
  background: transparent;
  border: 1px solid black;
  color: black;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
}
