* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  /* overflow-x: hidden; */
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right bottom, #61e1eb, #327bc5);
  border-radius: 25px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

.classSelection__header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  width: 80vw;
  padding: 20px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.classSelection__header > h1 {
  display: flex;
  justify-content: center;
  flex: 1;
  color: white;
}

.logout {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
}

.logout > h4 {
  margin-left: 10px;
}

.Modal {
  background-color: #bbb7b7;
}

.close-modal {
  position: absolute;
  padding: 6px 10px;
  font-size: 1.2rem;
  right: 1rem;
  top: 1.5rem;
  z-index: 1000;
  cursor: pointer;
}

.TFtimer__section {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TFtimer__section > label {
  font-weight: 600;
}

.TFtimer__section > input {
  outline-width: 0;
  margin-left: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.classMedium {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.classMedium {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classMedium > h1 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 20px 30vw;
  border-radius: 8px;
}

.selectContainer__middleSelect {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.selectContainer__middleSelect > p {
  margin-right: 1rem;
  font-family: "Inter", sans-serif;
  width: 10vw;
  color: white;
}

.classMediumContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.classMediumContainer > button {
  outline-width: 0;
  border: none;
  font-size: 1.2rem;
  color: white;
  background-color: #fcaa87;
  padding: 10px 30px;
  border-radius: 9px;
  cursor: pointer;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.title > label {
  width: 8vw;
}

.title > input {
  outline-width: 0;
  width: 40vw;
  border: none;
  margin-bottom: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
}

.mockTest__row > .image {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
}

.fileContainer {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.fileContainer {
  display: flex;
  align-items: center;
}

.fileContainer > p {
  margin-top: 3px;
  color: white;
  width: 10vw;
}

.fileContainer > div > div > button {
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.fileContainer > div > div > button i {
  margin-right: 5px;
}
