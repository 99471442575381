@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.addBanner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
}

.addBannerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addBanner > button {
  margin-top: 5rem;
  margin-left: 1rem;
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: #fcaa87;
  font-weight: 600;
  color: white;
  margin: 1rem auto;
}

.addBanner > button:hover {
  background-color: #fcaa87;
}
