@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

* {
  font-family: "Inter", sans-serif;
}

.editMockTest {
  width: 95vw;
  min-height: 100vh;
  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.editMockTest {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editMockTest__containerTop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.preview__image {
  width: 30px;
  height: 35px;
  border-radius: 9px;
  background: beige;
  object-fit: contain;
  cursor: pointer;
}

/* .editMockTest__container {
      display: flex;
  } */

.editMockTest__container > button {
  margin-top: 2rem;
  margin-left: 1rem;
  padding: 10px 30px;
  margin-right: 1rem;
  cursor: pointer;
  border: none;
  outline-width: 0;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: #fcaa87;
  font-weight: 600;
  color: white;
  margin: 1rem auto;
}

.editMockTest__container > button:hover {
  background-color: #fcaa87;
}

.MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.edit__input {
  outline-width: 0;
  border: none;
  background-color: #f5e3dd;
  padding: 5px 10px;
  width: 80%;
  border-radius: 10px;
  margin-left: 5px;
}

.editMockTest > button {
  margin-left: 1rem;
  margin: 1rem 0;
  color: white;
  background-color: #fcaa87;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.editMockTest > button:hover {
  background-color: #fcaa87;
}
