.mockTestContainer {
  width: 100vw;
  min-height: 120vh;

  background: linear-gradient(
    124.29deg,
    #7e82f8 -40.64%,
    rgba(198, 58, 248, 0.9) 119.87%
  );
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mockTestContainer__top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
}

.section__fields {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}

.section__fields > p {
  color: #fff;
}

.select__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  margin-left: 2rem;
}

.text__container {
  color: #fff;
  margin-left: 2rem;
}

.text__container > input {
  padding: 8px;
  outline-width: 0;
  border: none;
  border-radius: 9px;
}

.mockTest__timer {
  color: #fff;
}

.mockTest__timer > input {
  padding: 8px;
  outline-width: 0;
  border: none;
  border-radius: 9px;
}

.section__buttons {
  display: flex;
}

.fileContainer {
  margin-bottom: 1rem;
}
